import {
  UrlSanitizerV2,
  createVinByRegExpRemover,
} from '@oneaudi/audi-tracking-service';
import { Logger } from '@feature-hub/logger';

const UUID_REGEX =
  /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;
const EMAIL_REGEX = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}/;

function splitPathAndCheckBase64EncodedValues(
  url: string,
  checker: (pathPart: string, decodedValue?: string) => string,
): string {
  return url
    .split('/')
    .map((pathPart) => {
      const decoded = Buffer.from(pathPart, 'base64').toString('utf-8');
      return checker(pathPart, decoded);
    })
    .join('/');
}

/**
 * This method will replace the GraphQl relay IDs used by myAudi with the specific place holder.
 * Normaly the base64 encoded ID will result in something like Vehicle:xxx
 * where Vehicle would be the id type. This will be used as the replacement string.
 *
 * @param url
 * @returns
 */
function replaceRelayIdsInUrl(url: string): string {
  if (!url) {
    return '';
  }

  return splitPathAndCheckBase64EncodedValues(url, (pathPart, decodedValue) => {
    const decoded = decodedValue.split(':');

    if (decoded && decoded.length > 1 && decoded[0].match(/^\w+$/)) {
      return `${decoded[0].toLowerCase()}-id`;
    }
    return pathPart;
  });
}

function replaceWithRegex(
  url: string,
  regex: RegExp,
  replacement: string,
): string {
  return splitPathAndCheckBase64EncodedValues(url, (pathPart, decodedValue) => {
    if (decodedValue) {
      const potentialReplacement = decodedValue.replace(regex, replacement);

      if (potentialReplacement === replacement) {
        return potentialReplacement;
      }
    }
    return pathPart.replace(regex, replacement);
  });
}

function replaceVin(url: string, vinSanitizer: UrlSanitizerV2): string {
  return splitPathAndCheckBase64EncodedValues(url, (pathPart, decodedValue) => {
    if (decodedValue) {
      const potentialReplacement = vinSanitizer(decodedValue);

      if (potentialReplacement === 'vehicle-id') {
        return potentialReplacement;
      }
    }
    return vinSanitizer(pathPart);
  });
}

function sanitizeValue(value: string, vinSanitizer: UrlSanitizerV2): string {
  let newValue = replaceWithRegex(value, UUID_REGEX, 'UUID');
  newValue = replaceWithRegex(newValue, EMAIL_REGEX, 'EMAIL');
  newValue = replaceRelayIdsInUrl(newValue);
  newValue = replaceVin(newValue, vinSanitizer);

  return newValue;
}

export function removePrivateData(
  urlPath: string,
  logger: Logger = console,
): string {
  // We will use this to handle query parameters, and the urlPath should always be an actual path.
  const parsedUrl = new URL(urlPath, 'https://localhost');
  const vinSanitizer = createVinByRegExpRemover(logger);

  let newUrl = urlPath;

  // We search and replace with this in the different potential query parameters
  if (parsedUrl.search) {
    parsedUrl.searchParams.forEach((value) => {
      const newValue = sanitizeValue(value, vinSanitizer);
      newUrl = newUrl.replace(value, newValue);
    });
  }

  return sanitizeValue(newUrl, vinSanitizer);
}
