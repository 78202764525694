import * as Cookies from 'js-cookie';

const metaValues: Record<string, { dom: Element; value: string }> = {};

export enum Platform {
  FALCON = 'FALCON',
  NEMO = 'NEMO',
}

export type KnownNemoMetaTags =
  | 'locale-market'
  | 'locale-country'
  | 'locale-currency'
  | 'i18n-endpoint'
  | 'locale-language'
  | 'locale-currencyType'
  | 'vue-agw-endpoint'
  | 'locale-currencyISOCode'
  | 'locale-showDecimals'
  | 'carline'
  | 'carlinegroup'
  | 'prstring'
  | 'cola-api-url'
  | 'myaudi-logout-url'
  | 'myaudi-auth-proxy'
  | 'wag-baseurl'
  | 'locale-iso-3166-1-alpha3'
  | 'mediaservice-pre-eos-jwt2cookie'
  | 'cms-platform'
  | 'bat-signal';

export const getMetaContent = (
  dom: Element,
  metaName: KnownNemoMetaTags,
): string => {
  if (!metaValues[metaName] || dom != metaValues[metaName].dom) {
    const metatag = dom.querySelector(`meta[name=${metaName}]`);
    metaValues[metaName] = {
      dom,
      value: metatag?.getAttribute('content') || '',
    };
  }
  return metaValues[metaName].value;
};

export const getPreviewToken = (dom: Element): string => {
  let jwtToken = '';

  const isPreview = Cookies.get('nemo_preview');

  if (isPreview === 'true') {
    const countryCode = getMetaContent(dom, 'locale-market');
    const language = getMetaContent(dom, 'locale-language');
    const jwtCookieName = `jwt_${countryCode}_${language}`;
    jwtToken = Cookies.get(jwtCookieName);
  }

  return jwtToken;
};

export const getPlatform = (dom: Element): Platform => {
  const platform = getMetaContent(dom, 'cms-platform');

  if (platform === 'falcon') {
    return Platform.FALCON;
  }

  return Platform.NEMO;
};

export const getPrimaryConsumerId = (dom: Element): string => {
  const featureAppElements = Array.from(
    dom.getElementsByTagName('feature-app'),
  );

  const primaryConsumerElement = featureAppElements.find((singleElement) => {
    return singleElement.getAttribute('primary-consumer') === 'true';
  });

  if (!primaryConsumerElement) {
    return '';
  }

  return primaryConsumerElement.getAttribute('id') || '';
};
